body {
  margin: 0;
  background-color: #1f2833;
  overflow: hidden;
}

#algorithmVisuliser_container {
  height: 100vh;
  position: relative;
}

.controlBar {
  position: relative;
  width: 100;
  height: 10%;
  margin: 20px;
  color: #f5828f;
}

.topSlider {
  margin-left: 20px;
}

.sliderText {
  font-size: 20px;
  font-weight: 800;
  color: #45a29e;
  font-family: "Sriracha", cursive;
}

.floatLeft {
  float: left;
}

.darkLightSwitch-switch {
  transform: translateX(25%);
}

.darkLightSwitch {
  float: right;
  margin-right: 35px;
}

.darkLightSwitch-text {
  color: #45a29e;
  font-family: "Sriracha", cursive;
}

.githubLink {
  float: right;
  margin: 10px;
  margin-top: 30px;
}
