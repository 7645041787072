.algorithmInfo {
  cursor: auto;
}

.algorithmInfo-Dropdown {
  display: none;
  position: absolute;
  background-color: #00ffee;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  transform: translate(calc(-50% + 20px), 10px);
  border-radius: 10%;
  min-width: 276px;
  flex-direction: column;
  z-index: 1;
  justify-content: center;
  padding: 10px;
}

.hvr-bubble-top:before {
  pointer-events: none;
  position: absolute;
  z-index: -1;
  content: "";
  border-style: solid;
  transition-duration: 0.3s;
  transition-property: transform;
  left: calc(50% - 20px);
  top: 0;
  border-width: 0 20px 20px 20px;
  border-color: transparent transparent #00ffee transparent;
  cursor: text;
}
.algorithmInfo:hover .hvr-bubble-top:before {
  transform: translateY(-20px);
}

.algorithmInfo-title {
  white-space: nowrap;
  font-size: larger;
}

.algorithmInfo-row1 {
  border-top: solid 1px #000;
  margin-bottom: 5px;
  text-transform: none;
}

.algorithmInfo-row3 {
  border-top: solid 1px #000;
}

.algorithmInfo-hedder {
  font-size: medium;
  text-transform: none;
  color: rgb(75, 75, 75);
  font-weight: 900;
  font-family: "Dosis", sans-serif;
}

.algorithmInfo-colourContainer {
  display: inline-block;
  margin: 5px;
  justify-content: center;
}

.algorithmInfo-colourSquare {
  height: 30px;
  width: 30px;
  position: relative;
  border: solid 2px #000;
  transform: translateX(-55%);
}

.algorithmInfo-colourDescription {
  text-align: center;
  text-transform: lowercase;
  font-weight: 600;
}

.algorithmInfo:hover .algorithmInfo-Dropdown {
  display: block;
}

.algorithmInfo-oContainer {
  position: relative;
  display: inline-block;
  width: 22%;
  margin: 10px;
  padding: 3px;
  text-transform: none;
}

.algorithmInfo-oTitle {
  border-bottom: solid 1px #000;
}

.algorithmInfo-oText {
  padding-top: 3px;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  font-size: large;
}

.algorithmInfo-oTextContainer {
  position: relative;
  background-color: black;
}
