.arrayContainer {
  position: relative;
  display: flex;
  flex-wrap: nowrap;
  justify-content: center;
  height: 81%;
  width: 98%;
  left: 1%;
  text-align: center;
  white-space: nowrap;
}

.arrayItem {
  position: relative;
  font-size: large;
  color: #0b0c10;
  font-family: "Oswald", sans-serif;
}
